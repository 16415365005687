export const ENV = {
  REACT_APP_APOLLO_GRAPHQL_URL: process.env
    .REACT_APP_APOLLO_GRAPHQL_URL as string,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
  REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  REACT_APP_WEBHOOK_URL: process.env.REACT_APP_WEBHOOK_URL as string,
};

export const checkEnvVars = () => {
  // check if any env is undefined
  Object.keys(ENV).forEach((key) => {
    const val = (ENV as any)[key];
    if (!val) {
      throw new Error(`Env "${key}" is undefined`);
    }
  });
};
