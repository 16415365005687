import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Stack,
  Radio,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import { useEffect, useState } from "react"
import { centsToUsd } from "../utils/format"
import { useMutation } from "@apollo/client"
import { INSERT_TRANSACTION } from "../queries/transaction"

type HandleSaveFn = (props: { wallet_id?: string }) => void

interface EditBalancePopupProps {
  open: boolean
  handleClose: () => void
  handleEdited: () => void
  profile: any
}

const EditBalancePopup = ({ open, handleClose, handleEdited, profile }: EditBalancePopupProps) => {
  const [selectedType, setSelectedType] = useState("AddAmount")
  const [amountAdd, setAmountAdd] = useState(0)
  const [amountReduce, setAmountReduce] = useState(0)
  const [loading, setLoading] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmountAdd(0)
    setAmountReduce(0)
    setSelectedType(event.target.value)
  }

  const handleCloseClick = () => {
    setAmountAdd(0)
    setAmountReduce(0)
    handleClose()
  }

  const handleAmountAddChange = (event: any) => {
    setAmountAdd(Number(event.target.value))
  }

  const handleAmountReduceChange = (event: any) => {
    setAmountReduce(Number(event.target.value))
  }

  const [insertTxn] = useMutation(INSERT_TRANSACTION)

  const amount = selectedType === "AddAmount" ? amountAdd : amountReduce

  const handleSaveAmount = () => {
    if (amount <= 0) {
      alert("Please input amount")
      return
    }

    if (!profile?.wallet_id) {
      alert("Wallet missing")
      return
    }

    const amountUsdCents = amount * 100

    const accountantWalletId = "44444444-4444-4444-4444-444444444444"

    const walletIdToCredit = selectedType === "AddAmount" ? profile.wallet_id : accountantWalletId
    const walletIdToDebit = selectedType === "AddAmount" ? accountantWalletId : profile.wallet_id

    let description = "Adjusted by Accountant"

    const params = {
      amount_usd_cents: amountUsdCents,
      description,
      credit: walletIdToCredit,
      debit: walletIdToDebit,
      // initiated_by: "",
      type: "MANUAL_ADJUSTMENT",
    }

    setLoading(true)

    console.log("Handle save: amount = " + amount + " ; wallet = " + profile.wallet_id)
    console.log(JSON.stringify(params))

    insertTxn({
      variables: params,
    })
      .then((res) => {
        console.log("res: ", { res })
        handleEdited()
        alert("Success")
      })
      .catch((err) => {
        console.log("err: ", { err, params })
        alert("Error: " + err.message)
      })
      .finally(() => {
        setLoading(false)
        handleCloseClick()
      })
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Stack alignItems={"flex-start"} spacing={"24px"} sx={{ width: "410px", padding: "24px" }}>
            <Typography variant="h4" textAlign={"center"}>
              Edit Balance
            </Typography>

            <Typography variant="body1" textAlign={"center"}>
              Current Balance: {centsToUsd(profile?.wallet?.amount_usd_cents)}
            </Typography>
            <Stack spacing="12px" width={"100%"}>
              <Stack spacing={"4px"}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Radio
                    checked={selectedType === "AddAmount"}
                    onChange={handleChange}
                    value="AddAmount"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "AddAmount" }}
                  />
                  <Typography variant="body2" textAlign={"center"}>
                    Add Amount
                  </Typography>
                </Stack>
                {selectedType === "AddAmount" && (
                  <TextField onChange={handleAmountAddChange} id="outlined-number" size="small" label="" type="number" />
                )}
              </Stack>
              <Stack spacing={"4px"}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Radio
                    checked={selectedType === "ReduceAmount"}
                    onChange={handleChange}
                    value="ReduceAmount"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "ReduceAmount" }}
                  />
                  <Typography variant="body2" textAlign={"center"}>
                    Reduce amount
                  </Typography>
                </Stack>
                {selectedType === "ReduceAmount" && (
                  <TextField onChange={handleAmountReduceChange} id="outlined-number" size="small" label="" type="number" />
                )}
              </Stack>
            </Stack>
            <Stack alignItems={"flex-end"} width={"100%"}>
              <Stack direction="row" spacing={"24px"} sx={{ mt: 2, justifyContent: "center" }}>
                <Button variant="outlined" sx={{ flexGrow: 1 }} color="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ flexGrow: 1 }} onClick={handleSaveAmount}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default EditBalancePopup
