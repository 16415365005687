import {
  Edit,
  FunctionField,
  Resource,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import * as Tabs from "@radix-ui/react-tabs";
import styled from "styled-components";
import { centsToUsd } from "../utils/format";
import { TransactionHistoryList } from "../features/TxnList";

enum TabId {
  Information = "Information",
  Survey = "Survey",
  TransactionHistory = "TransactionHistory",
}

const TABS = [
  {
    id: TabId.Information,
    label: "Information",
  },
  {
    id: TabId.Survey,
    label: "Survey",
  },
  {
    id: TabId.TransactionHistory,
    label: "Transaction History",
  },
];

export const MerchantForm = () => {
  const record = useRecordContext();
  console.log("record: ", record);

  const handleSubmit = async (values: any) => {
    console.log("values: ", values);
  };

  const renderTabInformation = () => {
    return (
      <TabContentWrapper>
        <FunctionField
          render={(record: any) =>
            (console.log("record: ", record) as any) || (
              <div>
                <img
                  src={record?.logo_file?.url || "https://placehold.co/120x120"}
                  width={120}
                  height={120}
                  alt="Merchant's avatar"
                  style={{ borderRadius: "50%" }}
                />
              </div>
            )
          }
        />

        <div>
          <TextInput label="Merchant Name" source="company_name" fullWidth />
        </div>
        <div>
          <TextInput label="Phone" source="phone_number" fullWidth />
        </div>
        <OneLine>
          <TextInput label="Country" source="country_code" />
          <TextInput label="Postalcode" source="postal_code" />
        </OneLine>

        <OneLine>
          <TextInput label="City" source="region" />
          <TextInput label="State" source="region2" />
        </OneLine>

        <div>
          <TextInput label="Address 1" source="address" fullWidth />
        </div>

        <div>
          <TextInput label="Address 2" source="address2" fullWidth />
        </div>

        <div>
          <TextInput
            label="Display currency"
            source="display_currency"
            fullWidth
          />
        </div>
      </TabContentWrapper>
    );
  };

  const renderTabSurvey = () => {
    return (
      <TabContentWrapper>
        <TextInput
          label="Have you or are currently running influencer marketing campaigns?"
          source="client_survey.is_currently_running_campaigns"
          disabled
          fullWidth
        />
        <TextInput
          label="How many campaigns annually"
          source="client_survey.number_of_campaigns_annually"
          disabled
          fullWidth
        />
        <TextInput
          label="How many kols per campaign on average"
          source="client_survey.avg_kols_per_campaign"
          disabled
          fullWidth
        />

        <FunctionField
          render={(record: any) => (
            <TextInput
              label="How much budget per campaign on average"
              source=""
              defaultValue={centsToUsd(
                record?.client_survey?.avg_budget_per_campaign_usd_cents
              )}
              disabled
              fullWidth
            />
          )}
        />
      </TabContentWrapper>
    );
  };

  const renderTabTransactionHistory = () => {
    return (
      <div>
        <Resource name="myTransactionHistory" list={TransactionHistoryList} />
      </div>
    );
  };

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit as any} toolbar={false}>
        <Tabs.Root defaultValue={TabId.Information} style={{ width: "100%" }}>
          <TabsList aria-label="Tabs">
            {TABS.map((tab) => (
              <TabsTrigger value={tab.id}>{tab.label}</TabsTrigger>
            ))}
          </TabsList>

          <TabsContent value={TabId.Information}>
            {renderTabInformation()}
          </TabsContent>
          <TabsContent value={TabId.Survey}>{renderTabSurvey()}</TabsContent>
          <TabsContent value={TabId.TransactionHistory}>
            {renderTabTransactionHistory()}
          </TabsContent>
        </Tabs.Root>
      </SimpleForm>
    </Edit>
  );
};

const OneLine = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  & > * {
    flex: 1;
  }
`;

const TabsList = styled(Tabs.List)`
  display: flex;
  & > * {
    flex: 1;
  }
  margin-bottom: 16px;
`;

const TabsTrigger = styled(Tabs.Trigger)`
  padding: 14px 0;
  color: #1d1b20;
  font-weight: 600;
  border: none;
  background-color: rgba(29, 27, 32, 0.12);
  cursor: pointer;

  &[data-state="active"] {
    background-color: #fef7ff;
  }
`;

const TabsContent = styled(Tabs.Content)`
  padding: 16px;
  border: 1px solid #cac4d0;
  background: #fff;
`;

const TabContentWrapper = styled.div`
  max-width: 500px;
`;
