import { gql } from "@apollo/client"

export const GET_TRANSACTION_HISTORY = gql`
  query GetTxnHistory($profile_id: uuid!, $profile_type: ProfileType!) {
    myTransactionHistory(kol_or_merchant_profile_id: $profile_id, profile_type: $profile_type) {
      id
      amount_usd_cents
      balance_usd_cents
      credit
      debit
      description
      external_transaction_id
      initiated_by
      merchant_image_url
      timestamp
      title
      transaction_type
    }
  }
`

export const INSERT_TRANSACTION = gql`
  mutation insert_txn(
    $amount_usd_cents: numeric!
    $description: String!
    $credit: uuid!
    $debit: uuid!
    $type: TransactionType!
    $reference_transaction_ids: [uuid]
    $reverted_by: uuid
  ) {
    createTransaction(
      amount_usd_cents: $amount_usd_cents
      debit: $debit
      credit: $credit
      type: $type
      description: $description
      reference_transaction_ids: $reference_transaction_ids
      reverted_by: $reverted_by
    ) {
      amount_usd_cents
      credit
      description
      external_transaction_id
      id
      initiated_by
      reverted_by
      type
    }
  }
`

export const GET_TRANSACTION_BY_IDS = gql`
  query GetTxnByIds($ids: [uuid!]) {
    transaction(where: { id: { _in: $ids } }) {
      id
      amount_usd_cents
      debit
      credit
      type
      initiated_by
      description
      timestamp
      external_transaction_id
      debit_balance_usd_cents
      credit_balance_usd_cents
      reverted_by
      document_id
      document_id_sent_receipt
      campaign_id
      receipt_sent_at
      reference_transaction_id
    }
  }
`;

export const GET_ADJUSTMENT_TRANSACTIONS = gql`
  query GetAdjustmentTransaction {
    transaction(where: { type: { _eq: "MANUAL_ADJUSTMENT" } }) {
      id
      amount_usd_cents
      debit
      credit
      type
      initiated_by
      description
      timestamp
      external_transaction_id
      debit_balance_usd_cents
      credit_balance_usd_cents
      reverted_by
      document_id
      document_id_sent_receipt
      campaign_id
      receipt_sent_at
      reference_transaction_id
    }
  }
`

export const UPDATE_DOCUMENT_ID_FOR_TNX = gql`
  mutation update_document_id($id: uuid!, $document_id: uuid!) {
    update_transaction(where: { id: { _eq: $id } }, _set: { document_id: $document_id }) {
      affected_rows
    }
  }
`

export const GET_KOLS = gql`
  query GetKols {
    kol_profile {
      wallet_id
      individual_fullname
      bank_name
      bank_account_no
      first_name
      last_name
    }
  }
`

export const GET_KOLS_BY_WALLET_ID = gql`
  query kol_profile_by_wallet_id($wallet_ids: [uuid]) {
    kol_profile(where: { wallet_id: { _in: $wallet_ids } }) {
      bank_name
      bank_account_no
      id
      wallet_id
    }
  }
`

export const GET_CAMPAIGNS_IDS_BY_TXN_IDS = gql`
  query kol_profile_by_wallet_id($ids: [uuid]) {
    transaction(where: { id: { _in: $ids } }) {
      id
      campaign_id
      reference_transaction_id
    }
  }
`

export const GET_DOCUMENTS_IDS_BY_TXN_IDS = gql`
  query get_document_ids_by_tnx_ids($ids: [uuid]) {
    transaction(where: { id: { _in: $ids } }) {
      id
      document_id
    }
  }
`

export const GET_DOCUMENTS_BY_IDS = gql`
  query file_metadata($ids: [uuid]) {
    file_metadata(where: { id: { _in: $ids } }) {
      id
      original_name
      url
    }
  }
`

export const GET_CAMPAIGN_INFO_BY_IDS = gql`
  query kol_profile_by_wallet_id($ids: [uuid]) {
    campaign(where: { id: { _in: $ids } }) {
      id
      name
    }
  }
`;

export const GET_TRANCHE_INFO_BY_CAMPAIGN_IDS = gql`
  query kol_profile_by_wallet_id($campaignIds: [uuid]) {
    tranche(where: {campaign_id: {_in: $campaignIds}}) {
      name
      id
      campaign_id
    }
  }
`

export const GET_KOL_NAME_BY_WALLET_ID = gql`
  query GetKolNameByWalletId($wallet_id: uuid!) {
    kol_profile(where: { wallet_id: { _eq: $wallet_id } }) {
      individual_fullname
    }
  }
`

export const GET_WALLET_ID_BY_KOL_ID = gql`
  query GetWalletIdByKolId($kol_id: uuid!) {
    kol_profile(where: { id: { _eq: $kol_id } }) {
      wallet_id
    }
  }
`;

export const SEND_RECEIPT = gql`
  mutation sendTransactionReceipt ($transactionId: uuid!) {
    sendTransactionReceipt (transactionId: $transactionId) {
      success
      message
    }
  }
`;

