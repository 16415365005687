import { List, Datagrid, TextField, useRecordContext, FunctionField, useDataProvider, GetListParams, useRefresh, useNotify, Button, useListContext } from 'react-admin';
import { DatagridActionsColumn } from '../shared/components/DatagridActionsColumn';
import { RowButton } from '../shared/components/RowButton';

import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { centsToUsd, formatDatetime } from '../utils/format';

import Select from 'react-select';
import exportFromJSON from 'export-from-json'
import { useApolloClient } from "@apollo/client";
import ImgUploadDocument from '../assets/ic_upload_document.svg';
import ImgDeleteDocument from '../assets/ic_delete_document.svg';
import ImgReceiptSent from '../assets/ic_receipt_sent.svg';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { UPDATE_DOCUMENT_ID_FOR_TNX, SEND_RECEIPT } from '../queries/transaction';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


const UPLOAD_DOCUMENT_ENDPOINT = `${process.env.REACT_APP_WEBHOOK_URL}/files`;

const showTransactionId = false;


const RevertButtonOrEmpty = () => {
    const record = useRecordContext();

    if (!record) return null;

    if (record.type === "KOL_TRANSFER" && !record.reverted_by) {
        return (
            <RowButton label="Revert" />
        )
    }

    return <span></span>
}

const TransactionTypeFormatted = () => {
    const record = useRecordContext();
    if (!record) return null;

    if (record.type === "MANUAL_ADJUSTMENT") {
        return <span style={{ color: '#6750A4' }}>MANUAL ADJUSTMENT</span>
    }

    if (record.reverted_by) {
        return <span style={{ color: 'red' }}>REVERTS TRANSFER</span>
    }

    if (record.type === "KOL_TRANSFER") {
        return <span style={{ color: 'green' }}>TRANSFER</span>
    }

    return <span style={{ color: 'red' }}>REVERTS TRANSFER</span>
}


interface TransactionTypeDropdownProps {
    onChange: (value: string) => void;
}

const TransactionTypeSelection = forwardRef<any, TransactionTypeDropdownProps>(({ onChange }, ref) => {
    const options = [
        { value: '', label: 'All' },
        { value: 'MANUAL_ADJUSTMENT', label: 'ADJUSTMENT' },
        { value: 'KOL_TRANSFER', label: 'TRANSFER' },
        { value: 'KOL_TRANSFER_REVERT', label: 'REVERTED' },
    ];

    const handleChange = (selectedOption: any) => {
        onChange(selectedOption.value);
    };

    const selectRef = useRef<any>(null);


    useImperativeHandle(ref, () => ({
        openSelectMenu: () => {
            console.log('openSelectMenu: ', selectRef);
            if (selectRef.current) {
            }
        },
        closeSelectMenu: () => {
            console.log('closeSelectMenu: ', selectRef);
            if (selectRef.current) {
            }
        },
    }));

    return (
        <Select options={options} onChange={handleChange} ref={selectRef} styles={{
            container: (provided) => ({
                ...provided,
                width: 300,
            }),
        }}
            placeholder="Select an option"
            defaultValue={options[0]}
        />
    );
});

interface SelectedCountProps {
    selectedIds: any[];
    onAbort: () => void;
    onExport: () => void;
}

const SelectedCount: React.FC<SelectedCountProps> = ({ selectedIds, onAbort, onExport }) => {

    const text = selectedIds.length > 1 ? `${selectedIds.length} items selected` : '1 item selected';
    if (selectedIds.length == 0) {
        return <span></span>
    }
    return (
        <div style={{ height: "50px", backgroundColor: '#d1e8fc', alignContent: 'center', alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
            <div style={{ padding: '10px', display: 'flex' }}>
                <CloseIcon onClick={onAbort} style={{ cursor: 'pointer', color: '#1a63ca', marginRight: '10px' }} />
                <div style={{ color: '#1a63ca' }}>{text}</div>
            </div>
            <Button
                label="EXPORT"
                onClick={onExport}
            ></Button>
        </div>
    );
}

interface KolDropDownProps {
    onChange: (value: string) => void;
}

const KolSelection: React.FC<KolDropDownProps> = ({ onChange }) => {
    const dataProvider = useDataProvider();
    const [options, setOptions] = useState([{ value: '', label: 'All Influencers' }]);

    useEffect(() => {
        dataProvider.getList('kol_profile', {} as GetListParams)
            .then(({ data }) => {
                var newOptions = data
                    .map(kol => ({ value: kol.id, label: kol.individual_fullname }));
                newOptions.unshift({ value: '', label: 'All Influencers' });
                newOptions = newOptions.filter((record: any) => record.label !== null && record.label !== '');
                console.log('kol_profile_data: ', newOptions);
                setOptions(newOptions);
            })
            .catch(error => {
                // Handle the error
            });
    }, []);

    const handleChange = (selectedOption: any) => {
        onChange(selectedOption.value);
    };

    return (
        <Select
            options={options}
            onChange={handleChange}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: 300,
                }),
            }}
            placeholder="Select a KOL"
            defaultValue={options[0]}
        />
    );
}

const exporter = (transactions: any) => {
    const data = transactions.map((transaction: any) => {
        return {
            'KOL Full Name': transaction.individual_fullname,
            'KOL\'s name': transaction.kol_name ?? '',
            'Bank name': transaction.bank_name ?? '',
            'Bank account': transaction.bank_account_no ?? '',
            'Transaction Detail': transaction.campaign_info ?? '',
            'Transaction Type': transaction.type,
            'Amount in USD': centsToUsd(transaction.amount_usd_cents),
            'Created At': formatDatetime(transaction.timestamp),
        };
    });
    console.log('data_exporter', data);
    const fileName = 'transactions';
    const exportType = 'csv';
    exportFromJSON({ data, fileName, exportType });
}

export const TransactionBulkActionButtons = (props: any) => (
    <React.Fragment>
        <div style={{ height: "50px", backgroundColor: 'transparent', alignContent: 'center', alignItems: 'center' }}>
            <Button
                label="Export"
                onClick={() => {
                    const storedTransactions = localStorage.getItem('transactions');
                    const parsedTransactions = storedTransactions ? JSON.parse(storedTransactions) : [];
                    if (props && props.selectedIds) {
                        const dataToExport = parsedTransactions.filter((transaction: any) => props.selectedIds.includes(transaction.id));
                        exporter(dataToExport);
                    } else {
                        exporter(parsedTransactions);
                    }
                }}></Button>
        </div>
    </React.Fragment>
);

const UploadDocumentButton = (props: any) => {
    const record = useRecordContext();
    const label = record?.document_link ? 'EDIT' : 'UPLOAD';
    const marginLeft = record.type === "KOL_TRANSFER" && !record.reverted_by ? '10px' : '0px';
    return (
        <div style={{ marginLeft: marginLeft }}>
            <Button
                color="secondary"
                variant="outlined"
                label={label}
                {...props}
                onClick={() => {
                    localStorage.setItem('selected_transaction', JSON.stringify(record));
                    props.onClick();
                }}
            />
        </div>
    );
}

const SendReceiptButton = (props: any) => {
    const record = useRecordContext();
    const label = 'SEND RECEIPT';
    if (record.type != "KOL_TRANSFER" || !record.document_id) {
        return <span></span>
    }
    const opacity = record?.document_id_sent_receipt == record?.document_id ? 0.3 : 1
    return (
        <div style={{ marginLeft: '10px', opacity: opacity }}>
            <Button
                color="secondary"
                variant="outlined"
                label={label}
                {...props}
                onClick={() => {
                    console.log('record', record);
                    if (opacity == 0.3) {
                        return;
                    }
                    localStorage.setItem('selected_transaction', JSON.stringify(record));
                    props.onClick();
                }}
            />
        </div>
    );
}



export const ActualPayouts = (props: any) => {
    console.log('propsData', props);
    const [filter, setFilter] = useState({ type: '', kol_id: '' });
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const refresh = useRefresh()
    const notify = useNotify()
    const client = useApolloClient()
    const [selectedTransaction, setSelectedTransaction] = useState<any>(undefined);
    const [cachedFile, setCachedFile] = useState<any>(undefined);
    const [showEditDocument, setShowEditDocument] = useState(false);
    const [showConfirmSendReceipt, setShowConfirmSendReceipt] = useState(false);

    const [selectedIds, setSelectedIds] = useState<any[]>([]);
    const [selectedViewSectionIds, setSelectedViewSectionIds] = useState<any[]>([]);

    const [transactionTypeSelectionOpen, setTransactionTypeSelectionOpen] = useState(false);

    const handleSelect = (ids: any[]) => {
        setSelectedIds(ids);
        if (ids.length != 0) {
            setSelectedViewSectionIds(ids);
        } else {
            setTimeout(() => {
                setSelectedViewSectionIds([]);
            }, 500)
        }
    };

    const handleToggle = (id: any) => {
        const ids = selectedIds.includes(id) ? selectedIds.filter((selectedId) => selectedId !== id) : [...selectedIds, id];
        handleSelect(ids);
    }

    const uploadFile = async (file: any) => {

        const formData = new FormData();
        formData.append('file', file);
        const response = await fetch(UPLOAD_DOCUMENT_ENDPOINT, {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            setLoading(false);
            throw new Error('File upload failed');
        }

        const data = await response.json();
        return data
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (!file) {
            return;
        }

        const selectedTransaction = JSON.parse(localStorage.getItem('selected_transaction') || '{}');
        //Handle the case update document
        if (selectedTransaction?.document_link && selectedTransaction?.document_link !== '') {
            setCachedFile(file);
            setSelectedTransaction({ ...selectedTransaction, document_name: file.name });
        }
        //Handle the case create document
        else {
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            setLoading(true);
            const data = await uploadFile(file);
            const documentId = data.id;
            const transactionId = JSON.parse(localStorage.getItem('selected_transaction') || '{}').id;
            client.mutate({
                mutation: UPDATE_DOCUMENT_ID_FOR_TNX,
                variables: {
                    id: transactionId,
                    document_id: documentId,
                }
            }).then((data) => {
                setLoading(false);
                setTimeout(() => {
                    localStorage.removeItem('selected_transaction')
                    refresh();
                    notify('Document uploaded successfully');
                }, 500);
            }).catch((error) => {
                setLoading(false);
                notify('Failed to upload document');
            });
        }
    };

    const showFileSelector = () => {
        const selectedTransaction = JSON.parse(localStorage.getItem('selected_transaction') || '{}');
        if (selectedTransaction?.document_link && selectedTransaction?.document_link !== '') {
            setSelectedTransaction(selectedTransaction);
            setShowEditDocument(true);
        } else {
            if (fileInputRef.current) {
                fileInputRef.current.click();
            }
        }

    };

    return <>
        <div style={{ position: 'relative' }}>
            <p style={{ position: 'absolute', top: -10, left: 0, zIndex: 1000, display: selectedIds.length == 0 ? 'block' : 'none' }}><TransactionTypeSelection onChange={(value) => {
                const newFilter = { type: value, kol_id: filter.kol_id };
                console.log('filter', filter);
                setFilter(newFilter);
            }} ></TransactionTypeSelection></p>
            <p style={{ position: 'absolute', top: -10, left: 320, zIndex: 1000, display: selectedIds.length == 0 ? 'block' : 'none' }}><KolSelection onChange={(value) => {
                const newFilter = { type: filter.type, kol_id: value };
                console.log('filter', filter);
                setFilter(newFilter);
                //setFilter(filter);
            }}></KolSelection></p>
            <p style={{ position: 'absolute', top: -15, left: 0, right: 0, zIndex: 1001, display: setSelectedViewSectionIds.length != 0 ? 'block' : 'none' }}>
                <SelectedCount selectedIds={selectedViewSectionIds} onAbort={() => {
                    handleSelect([]);
                }}
                    onExport={() => {
                        const storedTransactions = localStorage.getItem('transactions');
                        const parsedTransactions = storedTransactions ? JSON.parse(storedTransactions) : [];
                        if (selectedIds.length > 0) {
                            const dataToExport = parsedTransactions.filter((transaction: any) => selectedIds.includes(transaction.id));
                            exporter(dataToExport);
                        } else {
                            exporter(parsedTransactions);
                        }
                        handleSelect([]);
                    }}
                />
            </p>

            <List {...props} sort={{ field: 'timestamp', order: 'DESC' }} filter={filter} exporter={exporter}

                actions={<div style={{ backgroundColor: 'transparent', marginRight: '27px' }}

                ><TransactionBulkActionButtons props={props} /></div>}
            >
                <Datagrid bulkActionButtons={<TransactionBulkActionButtons />}
                    onSelect={(ids) => {
                        handleSelect(ids);
                    }}
                    onToggleItem={(id) => {
                        handleToggle(id);
                    }}
                    selectedIds={selectedIds}

                >
                    <FunctionField label="" render={(record: any) => IconReceiptSent(record)} />
                    <TextField label="KOL Full Name" source="individual_fullname" />
                    {/* <FunctionField label="KOL Wallet ID" render={(record: any) => shortenUuid(record?.kol_wallet_id)} /> */}
                    <FunctionField label="Bank name" render={(record: any) => record?.bank_name} />
                    <FunctionField label="Bank account" render={(record: any) => record?.bank_account_no} />
                    <DatagridActionsColumn label="Transaction Type">
                        <TransactionTypeFormatted />
                    </DatagridActionsColumn>
                    <FunctionField label="Amount in USD" render={(record: any) => centsToUsd(record?.amount_usd_cents)} />
                    <FunctionField label="Document" render={(record: any) => record?.document_link ? <div style={{ maxWidth: '120px' }}><a style={{ color: '#6750A4', textDecoration: 'none' }} target="_blank" href={record?.document_link} download={record?.document_link}>{record?.document_name}</a></div> : ''} />
                    <FunctionField label="Created At" render={(record: any) => formatDatetime(record?.timestamp)} />
                    <DatagridActionsColumn label="Actions">
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <RevertButtonOrEmpty />
                            <UploadDocumentButton onClick={() => {
                                showFileSelector()
                            }} />
                            <SendReceiptButton onClick={() => {
                                setShowConfirmSendReceipt(true);
                                const transaction = JSON.parse(localStorage.getItem('selected_transaction') || '{}');
                                setSelectedTransaction(transaction);
                            }} />
                            <input
                                type="file"
                                accept=".pdf"
                                style={{ display: 'none' }}
                                id="fileInput"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </div>
                    </DatagridActionsColumn>
                </Datagrid>
            </List>

            {showConfirmSendReceipt && selectedTransaction && <ConfirmSendReceiptOverlay
                transaction={selectedTransaction}
                onCancel={() => {
                    setShowConfirmSendReceipt(false);
                    setSelectedTransaction(undefined);
                }}
                onSendReceipt={() => {
                    if (selectedTransaction.document_link && selectedTransaction.document_link !== '') {
                        client.mutate({
                            mutation: SEND_RECEIPT,
                            variables: {
                                transactionId: selectedTransaction.id,
                            }
                        }).then((data) => {
                            setTimeout(() => {
                                localStorage.removeItem('selected_transaction')
                                setSelectedTransaction(undefined);
                                setShowConfirmSendReceipt(false);
                                refresh();
                                notify('Receipt sent successfully');
                            }, 500);
                        }).catch((error) => {
                            setSelectedTransaction(undefined);
                            setShowConfirmSendReceipt(false);
                            notify('Failed to send receipt');
                        });
                    } else {
                        setShowConfirmSendReceipt(false);
                        showFileSelector();
                    }
                }}
            />}

            {selectedTransaction && showEditDocument && <EditDocumentOverlay
                transaction={selectedTransaction}
                onDeleteFile={() => {
                    const confirmDelete = window.confirm('Are you sure you want to delete this document?');
                    if (!confirmDelete) {
                        return;
                    }
                    const transactionId = JSON.parse(localStorage.getItem('selected_transaction') || '{}').id;
                    if (!transactionId) {
                        setLoading(false);
                        notify('Transaction ID not found');
                        return;
                    }
                    setLoading(true);
                    client.mutate({
                        mutation: UPDATE_DOCUMENT_ID_FOR_TNX,
                        variables: {
                            id: transactionId,
                            document_id: null,
                        }
                    }).then((data) => {
                        setLoading(false);

                        setTimeout(() => {
                            localStorage.removeItem('selected_transaction')
                            setSelectedTransaction(undefined);
                            setShowEditDocument(false)
                            refresh();
                            notify('Document deleted successfully');
                        }, 500);
                    }).catch((error) => {
                        setLoading(false);
                        setSelectedTransaction(undefined);
                        setShowEditDocument(false)
                        notify('Failed to delete document');
                    });
                }}
                onCancel={() => {
                    setSelectedTransaction(undefined);
                    setShowEditDocument(false)
                }}
                onSelectFile={() => {
                    if (fileInputRef.current) {
                        fileInputRef.current.click();
                    }
                }}
                onUploadFile={async () => {
                    if (cachedFile) {
                        setLoading(true);
                        const documentId = (await uploadFile(cachedFile)).id;

                        const transactionId = JSON.parse(localStorage.getItem('selected_transaction') || '{}').id;
                        if (!transactionId) {
                            setLoading(false);
                            notify('Transaction ID not found');
                            return;
                        }
                        client.mutate({
                            mutation: UPDATE_DOCUMENT_ID_FOR_TNX,
                            variables: {
                                id: transactionId,
                                document_id: documentId,
                            }
                        }).then((data) => {
                            setLoading(false);
                            setTimeout(() => {
                                localStorage.removeItem('selected_transaction')
                                setSelectedTransaction(undefined);
                                setShowEditDocument(false)
                                refresh();
                                notify('Document updated successfully');
                            }, 500);
                        }).catch((error) => {
                            setLoading(false);
                            setSelectedTransaction(undefined);
                            setShowEditDocument(false)
                            notify('Failed to update document');
                        });
                    }
                }}
            />}
            {loading && <LoadingIndicator />}
        </div>
    </>

};

const IconReceiptSent = (record: any) => {
    const width = 30;
    const height = 30;
    const receiptSentAt = `Receipt was sent at ${formatReceiptSendTimestamp(record?.receipt_sent_at)}`;
    return <div style={{ width: showTransactionId ? '200px' : `${width}px`, height: `${height}px`, display: 'flex' }}>
        {record?.document_id_sent_receipt && <LightTooltip title={receiptSentAt}>
            <img src={ImgReceiptSent} width={width} height={height} alt="My SVG" />
        </LightTooltip>}
        <div>
            {showTransactionId && <div style={{ fontSize: '10px', color: 'gray', marginLeft: '5px' }}>{`CAMPAIGN_ID: ${record?.campaign_id}`}</div>}
            {showTransactionId && <div style={{ fontSize: '10px', color: 'gray', marginLeft: '5px' }}>{`TYPE: ${record?.type}`}</div>}
            {showTransactionId && <div style={{ fontSize: '10px', color: 'gray', marginLeft: '5px' }}>{`TXN_ID: ${record?.id}`}</div>}
        </div>
    </div>

}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: '10px',
    },
}));

const LoadingIndicator: React.FC = () => {
    return (
        <div style={styles.overlay}>
            <p style={{ color: 'white' }}>Uploading...</p>
        </div>
    );
}

interface ConfirmSendReceiptOverlayProps {
    transaction: any;
    onCancel: () => void;
    onSendReceipt: () => void;
}

const ConfirmSendReceiptOverlay: React.FC<ConfirmSendReceiptOverlayProps> = ({ transaction, onCancel, onSendReceipt }) => {
    return (
        <div style={styles.overlay}>
            <div style={styles.centerSquare}>
                <div><h3>Send Receipt</h3></div>
                <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {transaction?.document_link && <div style={{ flexGrow: 1, marginRight: '10px' }}>{`You are going to send receipt to [${transaction.individual_fullname}], are you sure you want to proceed?`}</div>}
                    {!transaction?.document_link && <div style={{ flexGrow: 1, marginRight: '10px' }}>Document is required to send receipt. Please proceed uploading. </div>}
                </div>

                <div style={{ paddingTop: '20px', justifyContent: 'flex-end', height: '60px', marginTop: '20px', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: '20px' }}><div style={{ cursor: 'pointer', color: 'black', fontWeight: '500' }} onClick={onCancel}>Cancel</div></div>
                    <div style={{ cursor: 'pointer', color: '#6750A4', fontWeight: '500' }} onClick={() => {
                        onSendReceipt();
                    }}>{transaction?.document_link ? 'Send' : 'Upload'}</div>

                </div>
            </div>
        </div>
    );
}



interface EditDocumentOverlayProps {
    transaction: any;
    onSelectFile: () => void;
    onCancel: () => void;
    onUploadFile: () => void;
    onDeleteFile: () => void;
}

const EditDocumentOverlay: React.FC<EditDocumentOverlayProps> = ({ transaction, onSelectFile, onCancel, onUploadFile, onDeleteFile }) => {
    return (
        <div style={styles.overlay}>
            <div style={styles.centerSquare}>
                <div><h3>Edit Document</h3></div>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    onSelectFile()
                }}>
                    <img src={ImgUploadDocument} width={75} height={75} alt="My SVG" />
                </div>
                <div style={{ padding: '20px', border: '1px solid #CAC5CD', height: '60px', marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ flexGrow: 1, marginRight: '10px' }}>{transaction?.document_name}</div>
                </div>

                <div style={{ paddingTop: '20px', justifyContent: 'flex-end', height: '60px', marginTop: '20px', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexGrow: 1, marginRight: '10px' }}><div style={{ cursor: 'pointer', color: 'red', fontWeight: '500' }} onClick={onDeleteFile}>Delete</div></div>
                    <div style={{ cursor: 'pointer', color: 'black', fontWeight: '500' }} onClick={onCancel}>Cancel</div>
                    <div style={{ width: '20px' }}></div>
                    <div style={{ cursor: 'pointer', color: '#6750A4', fontWeight: '500' }} onClick={() => {
                        onUploadFile();
                    }}>Save</div>

                </div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    centerSquare: {
        width: '50%',

        backgroundColor: 'white',
        borderRadius: '20px',
        padding: '20px',
    }

};

const formatReceiptSendTimestamp = (timestamp: string): string => {
    if (!timestamp || timestamp.length === 0) {
        return '';
    }
    const date = new Date(timestamp);

    const hours24 = date.getUTCHours();
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours24 >= 12 ? 'PM' : 'AM';
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getUTCMonth()]; // Get full month name
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${hours12}:${minutes}${ampm} on ${month} ${day}, ${year}`;
};
