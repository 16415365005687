import React from "react";
import ReactDOM from "react-dom/client";
import { Entrance } from "./App";
import reportWebVitals from "./reportWebVitals";
import { checkEnvVars } from "./configs/env";
import { Theme } from "@radix-ui/themes";

import "@radix-ui/themes/styles.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

checkEnvVars();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Theme>
      <Entrance />
    </Theme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
