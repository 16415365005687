import { Edit, FunctionField, RadioButtonGroupInput, SimpleForm, TextInput } from "react-admin"
import * as Tabs from "@radix-ui/react-tabs"
import styled from "styled-components"
import { Fragment, useRef } from "react"
import { extractAvatar, extractBankDetails } from "../utils/extract"
import { Download } from "react-feather"
import { FileMetadata } from "../types/kolProfile"
import { useMutation } from "@apollo/client"
import { UPDATE_ONE_KOL_PROFILE } from "../hasura"
import { toast } from "../utils/toast"
import { SocialExpandable } from "../components/SocialExpandable"
import { useMetrics } from "../hooks/useMetrics"
import { useParams } from "react-router-dom"
import { useRole } from "../hooks/useRole"

enum TabId {
  Information = "Information",
  Payment = "Payment",
  SocialAccount = "SocialAccount",
}

const TABS_ACCOUNTANT = [
  {
    id: TabId.Information,
    label: "Information",
  },
  {
    id: TabId.Payment,
    label: "Payment",
  },
  {
    id: TabId.SocialAccount,
    label: "Social Account",
  },
]

const TABS_CAMPAIGN_MANAGER = [
  {
    id: TabId.Information,
    label: "Information",
  },
  {
    id: TabId.SocialAccount,
    label: "Social Account",
  },
]

export const KolProfileForm = () => {
  const params = useParams()
  // TODO: use native edit method
  // const { save, record } = useEditController({
  //   resource: "kol_profile",
  //   id: params.id,
  // });

  const [updateOneKolProfile] = useMutation(UPDATE_ONE_KOL_PROFILE)
  const { data: kolMetric } = useMetrics(params?.id as string)
  const role = useRole()

  const getTabs = () => {
    let TABS = TABS_ACCOUNTANT

    if (role === "campaign_manager") {
      TABS = TABS_CAMPAIGN_MANAGER
    }

    return TABS
  }

  const handleSubmit = async (values: any) => {
    console.log("values: ", values)
    try {
      const { id, bank_verification_status } = values
      const data = {
        id,
        bank_verification_status,
      }
      const res = await updateOneKolProfile({ variables: data })
      console.info("res: ", res)
      if (res?.data?.update_kol_profile?.affected_rows > 0) {
        toast.success("Update status successfully")
      } else {
        toast.error("Update status failed")
      }
    } catch (err) {
      console.error("err saving: ", err)
      toast.error("Update status failed: " + (err as any)?.message || "")
    }
  }

  const renderTabInfo = () => {
    return (
      <Fragment>
        <h2>Basic Information</h2>
        <div>
          <FunctionField
            render={(record: any) => (
              <img
                src={extractAvatar(record) || "https://placehold.co/120x120"}
                width={120}
                height={120}
                alt="User's avatar"
                style={{ borderRadius: "50%" }}
              />
            )}
          />
        </div>
        <FunctionField
          fullWidth={true}
          style={{ width: "100%" }}
          render={(record: any) => (
            <TextInput
              defaultValue={`${record?.first_name || ""} ${record?.last_name || ""}`}
              source=""
              label="Full name"
              disabled
              fullWidth={true}
            />
          )}
        />
        <RadioButtonGroupInput
          source="gender"
          choices={[
            { id: "MALE", name: "Male" },
            { id: "FEMALE", name: "Female" },
            { id: "OTHERS", name: "Others" },
          ]}
          disabled
        />
        <TextInput disabled label="DOB" source="date_of_birth" fullWidth={true} />
        <FunctionField
          fullWidth={true}
          style={{ width: "100%" }}
          render={(record: any) => (
            <TextInput
              defaultValue={(record?.mapping_kol_profile_languages || []).map((x: any) => x?.language?.title).join(", ")}
              source=""
              label="Spoken Language"
              disabled
              fullWidth={true}
            />
          )}
        />
        <TextInput disabled label="Occupation" source="occupation" fullWidth={true} />

        <h2>Address</h2>
        <OneLine>
          <TextInput disabled label="Mobile number" source="mobile_number" />
          <TextInput disabled label="Email" source="email" />
        </OneLine>
        <OneLine>
          <TextInput disabled label="Country" source="country_code" />
          <TextInput disabled label="City" source="address_city" />
        </OneLine>
        <OneLine>
          <TextInput disabled label="State" source="address_state" />
          <TextInput disabled label="City" source="address" />
        </OneLine>
        <OneLine>
          <TextInput disabled label="Postalcode" source="address_postal_code" />
          <TextInput disabled label="Currency display" source="display_currency" />
        </OneLine>

        <h2>Profile</h2>

        <TextInput disabled label="Bio" source="bio" multiline={true} fullWidth />

        <FunctionField
          label="Interests"
          fullWidth={true}
          style={{ width: "100%" }}
          render={(record: any) => (
            <TextInput
              defaultValue={(record?.product_categories || []).map((x: any) => x?.product_category?.name).join(", ")}
              source=""
              label="Interests"
              disabled
              fullWidth={true}
            />
          )}
        />
        <TextInput disabled label="Brands you've worked with in the past" source="past_brands_collaboration" fullWidth={true} />
        <OneLine>
          <TextInput disabled label="Min rate per photo post" source="min_rate_photo_usd_cents" />
          <TextInput disabled label="Min rate per video post" source="min_rate_video_usd_cents" />
        </OneLine>
      </Fragment>
    )
  }

  const renderTabPayment = () => {
    return (
      <Fragment>
        <h2>Personal Information</h2>
        <TextInput disabled label="Full name" source="individual_fullname" fullWidth={true} />
        {/* individual_id_no vs individual_passport_no */}
        <TextInput disabled label="Passport or ID no." source="individual_id_no" fullWidth={true} />
        <h2>Bank Details</h2>
        <TextInput disabled label="Bank Name" source="bank_name" fullWidth={true} />
        <TextInput disabled label="Bank SWIFT code" source="bank_swift_code" fullWidth={true} />
        <h2>Bank Address</h2>
        <OneLine>
          <TextInput disabled label="Country" source="bank_country" />
          <TextInput disabled label="City" source="bank_city" fullWidth={true} />
        </OneLine>
        <OneLine>
          <TextInput disabled label="State" source="bank_state" fullWidth={true} />
          <TextInput disabled label="Address" source="bank_address" fullWidth={true} />
        </OneLine>
        <TextInput disabled label="Bank Acc No" source="bank_account_no" fullWidth={true} />
        <h2>Payment Status</h2>
        <RadioButtonGroupInput
          source="bank_verification_status"
          choices={[
            { id: "VERIFIED", name: "Verified" },
            { id: "REJECTED", name: "Failed to verify" },
          ]}
          style={{ display: "flex", flexDirection: "column" }}
        />
        <button type="submit">Update Status</button>
        <h2>Bank Details Upload</h2>
        <p>Required photo have information of Bank account (Bank name, Bank address, Account Number)</p>
        <div>
          <p>Bank photos</p>
          <FunctionField
            fullWidth={true}
            style={{ width: "100%" }}
            render={(record: any) => (
              <PhotosList>
                {extractBankDetails(record)?.map((x, i) => (
                  <ImageDownload index={i} file_metadata={x.file_metadata} key={x.file_metadata.id} />
                ))}
              </PhotosList>
            )}
          />
        </div>
      </Fragment>
    )
  }

  const renderTabSocialAccount = () => {
    // "instagram"
    // "youtube"
    // "twitter"
    // "tiktok"
    // "facebook"
    const socialToIcon = {
      facebook: {
        id: "icon_social_fb",
        alt: "Facebook",
      },
      instagram: {
        id: "icon_social_insta",
        alt: "Instagram",
      },
      youtube: {
        id: "icon_social_youtube",
        alt: "Youtube",
      },
      tiktok: {
        id: "icon_social_tiktok",
        alt: "Tiktok",
      },
    }

    return (
      <FunctionField
        fullWidth={true}
        style={{ width: "100%" }}
        render={(record: any) => {
          const platforms = record?.social_platforms?.filter((x: any) => x?.platform_username !== undefined && x?.platform_username !== "") ?? [];
          if (platforms.length <= 0) {
            return <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>There are no social accounts connected.</div>
          }
          return <div style={{ display: "flex", flexDirection: "column", gap: 32 }}>
            {
              platforms.map((platform: any) => {
                const { social_platform_code, platform_username } = platform;
                const socialCode = (social_platform_code as string).toLowerCase();
                const iconId = socialToIcon[socialCode as keyof typeof socialToIcon]?.id;
                const metric = kolMetric?.kol_metrics_by_platforms?.find((item) => item.platform === social_platform_code);
                return (
                  <SocialExpandable
                    key={social_platform_code}
                    icon={`/images/${iconId}@2x.png`}
                    socialHandle={platform_username}
                    statsAvgLikes={0}
                    statsAvgViews={0}
                    statsEngagement={metric?.engagement || 0}
                    statsFollowers={metric?.followers_count || 0}
                  />
                )
              })
            }
          </div>
        }}
      />
    )
  }

  return (
    <Edit>
      <SimpleForm onSubmit={handleSubmit as any} toolbar={false}>
        <Tabs.Root defaultValue={TabId.Information} style={{ width: "100%" }}>
          <TabsList aria-label="Tabs">
            {getTabs().map((tab) => (
              <TabsTrigger value={tab.id}>{tab.label}</TabsTrigger>
            ))}
          </TabsList>

          <TabsContent value={TabId.Information}>{renderTabInfo()}</TabsContent>
          <TabsContent value={TabId.Payment}>{renderTabPayment()}</TabsContent>
          <TabsContent value={TabId.SocialAccount}>{renderTabSocialAccount()}</TabsContent>
        </Tabs.Root>
      </SimpleForm>
    </Edit>
  )
}

const OneLine = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  & > * {
    flex: 1;
  }
`

const PhotosList = styled.ul`
  list-style: none;
`

const PhotosListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

type ImageDownloadProps = {
  index: number
  file_metadata: FileMetadata
}

const ImageDownload = (props: ImageDownloadProps) => {
  const anchorRef = useRef<any>(null)

  const handleDownloadImage = () => {
    if (anchorRef.current) {
      anchorRef.current.click()
    }
  }

  return (
    <PhotosListItem>
      <img src={props.file_metadata.url} alt={`bank photo ${props.index}`} width={100} height={100} />
      <a
        href={props.file_metadata.url}
        download={`bank-photo-${props.index}.jpg`}
        style={{ display: "none" }}
        ref={anchorRef}
        target="_blank"
      />
      <Download size={32} color="#6750A4" style={{ cursor: "pointer" }} onClick={handleDownloadImage} />
    </PhotosListItem>
  )
}

const TabsList = styled(Tabs.List)`
  display: flex;
  & > * {
    flex: 1;
  }
  margin-bottom: 16px;
`

const TabsTrigger = styled(Tabs.Trigger)`
  padding: 14px 0;
  color: #1d1b20;
  font-weight: 600;
  border: none;
  background-color: rgba(29, 27, 32, 0.12);
  cursor: pointer;

  &[data-state="active"] {
    background-color: #fef7ff;
  }
`

const TabsContent = styled(Tabs.Content)`
  padding: 16px;
  border: 1px solid #cac4d0;
  background: #fff;
`
