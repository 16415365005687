import React, { useState, useMemo, useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Paper,
  Stack,
  Button,
  TablePagination,
} from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { ReactComponent as IconCheckEmpty } from "../assets/ic-check-empty.svg"
import { ReactComponent as IconCheckFull } from "../assets/ic-check-full.svg"
import { ReactComponent as IconCheckNotFull } from "../assets/ic-check-not-full.svg"
import { ReactComponent as IconExport } from "../assets/ic-export.svg"
import { useMutation, useQuery } from "@apollo/client"
import { GET_KOL_PAYOUT, GET_PAYOUT_DETAILS, KolPayout, KolPayoutAggregate, KolPayoutDetail } from "../queries/kol-payout"
import { INSERT_TRANSACTION } from "../queries/transaction"
import { centsToUsd } from "../utils/format"
import { toast } from "../utils/toast"
import { useNotify } from "react-admin"
import { formatDate } from "../utils/time"

function Row({
  row,
  selectedKolPayouts,
  handleSelectPayouts,
  trigger,
}: {
  row: KolPayout
  selectedKolPayouts: KolPayout[]
  handleSelectPayouts: (payout: KolPayout, details: KolPayoutDetail[]) => void
  trigger: number
}) {
  const { data, refetch } = useQuery<{ getTransactionsByKolProfileId: KolPayoutDetail[] }>(GET_PAYOUT_DETAILS, {
    variables: {
      kol_profile_id: row.kol_profile_id,
    },
  })
  const details = data?.getTransactionsByKolProfileId ?? []
  const [open, setOpen] = useState(false)
  const selectedDetails = selectedKolPayouts.find((payout) => payout.id === row.id)?.details ?? []
  const isEmpty = selectedDetails.length === 0
  const isFull = selectedDetails.length === details.length && details.length > 0
  const totalSelectedAmount = selectedDetails.reduce((sum: number, detail) => Number(detail.total_amount) + sum, 0)

  useEffect(() => {
    if (trigger > 0) {
      refetch()
    }
  }, [trigger])

  const handleSelectAll = () => {
    const unselecteDetails = details.filter(
      (detail) => !selectedDetails.some((selectedDetail) => selectedDetail.id === detail.id),
    )
    handleSelectPayouts(row, unselecteDetails)
  }

  const handleDeselectAll = () => {
    handleSelectPayouts(row, selectedDetails)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={isFull ? handleDeselectAll : handleSelectAll}>
            {isFull ? <IconCheckFull /> : isEmpty ? <IconCheckEmpty /> : <IconCheckNotFull />}
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{row.full_name}</TableCell>
        <TableCell>{row.payment_name}</TableCell>
        <TableCell>{row.bank_name}</TableCell>
        <TableCell>{row.bank_account}</TableCell>
        <TableCell>{centsToUsd(row.total_amount)}</TableCell>
        <TableCell>{centsToUsd(totalSelectedAmount)}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      {open &&
        details.map((detail, index: number) => (
          <TableRow key={`detail${index}`}>
            <TableCell>
              <IconButton onClick={() => handleSelectPayouts(row, [detail])}>
                {selectedDetails.some((selectedDetail) => selectedDetail.id === detail.id) ? (
                  <IconCheckFull />
                ) : (
                  <IconCheckEmpty />
                )}
              </IconButton>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              {detail.type === "MANUAL_ADJUSTMENT"
                ? `Adjustment on ${formatDate(detail.timestamp, "MM/dd/yyyy, hh:mm:ss a")}`
                : detail.full_name}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{centsToUsd(detail.total_amount)}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        ))}
    </>
  )
}

const PendingPayouts2 = () => {
  const notify = useNotify()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const { data, refetch } = useQuery<{ kol_payout: KolPayout[]; kol_payout_aggregate: KolPayoutAggregate }>(GET_KOL_PAYOUT, {
    variables: {
      limit: rowsPerPage,
      page: page,
    },
  })
  const kolPayouts = data?.kol_payout ?? []
  const totalCount = data?.kol_payout_aggregate?.aggregate?.count ?? 0
  const [selectedPayouts, setSelectedPayouts] = useState<KolPayout[]>([])
  const selectedUSDCents = useMemo(() => {
    const value = selectedPayouts.reduce((sum, payout) => {
      const detailUSDCents = payout.details.reduce((sum: number, detail: any) => sum + Number(detail.total_amount), 0)
      return sum + detailUSDCents
    }, 0)
    return value
  }, [selectedPayouts])
  const totalUSDCents = useMemo(() => {
    return kolPayouts.reduce((sum, payout) => sum + Number(payout.total_amount), 0)
  }, [kolPayouts])
  const [insertTxn] = useMutation(INSERT_TRANSACTION)
  const [trigger, setTrigger] = useState(0)

  const handleSelectPayouts = (payout: KolPayout, details: KolPayoutDetail[]) => {
    setSelectedPayouts((prev) => {
      let newPayouts = prev
      const index = newPayouts.findIndex((p) => p.id === payout.id)
      if (index !== -1) {
        let oldPayout = newPayouts[index]
        details.forEach((detail) => {
          if (oldPayout.details.findIndex((e: any) => e.id === detail.id) !== -1) {
            oldPayout.details = oldPayout.details.filter((e: any) => e.id !== detail.id)
          } else {
            oldPayout.details = [...oldPayout.details, detail]
          }
        })
        newPayouts = newPayouts.filter((e) => e.id !== payout.id)
        if (oldPayout.details.length > 0) {
          newPayouts = [...newPayouts, oldPayout]
        } else {
          newPayouts = [...newPayouts]
        }
      } else {
        newPayouts = [...newPayouts, { ...payout, details: details }]
      }
      return newPayouts
    })
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleExport = () => {
    const headerData = ["No", "Full Name", "Payment Name", "Bank Name", "Bank Account", "Total Amount"]
    const dataArray = kolPayouts.map((item, index) => {
      return [
        `"${index + 1}"`,
        `${item.full_name}`,
        `"${item.payment_name}"`,
        `"${item.bank_name}"`,
        `"${item.bank_account}"`,
        `"${(item.total_amount / 100).toLocaleString("en-US")}"`,
      ].join(",")
    })
    const csvString = [headerData.join(","), ...dataArray].join("\n")
    const blob = new Blob([csvString], { type: "text/csv" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "KolPayouts.csv"

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
  }

  const handlePayout = async () => {
    if (selectedUSDCents < 10000) {
      notify("The minimum selected payout amount must be greater than US$100.", { type: "error" })
      return
    }
    try {
      for (const payout of selectedPayouts) {
        const ids = payout.details.map((detail) => detail.id)
        const amount = payout.details.reduce((sum, detail) => sum + Number(detail.total_amount), 0)
        const notes = payout.details.map((detail) => detail.full_name).join(",")

        const data = {
          amount_usd_cents: amount,
          debit: payout.wallet_id,
          credit: "33333333-3333-3333-3333-333333333333",
          type: "KOL_TRANSFER",
          description: notes,
          reference_transaction_ids: ids,
        }

        if (amount < 0) {
          notify("The selected amount cannot be negative amount", { type: "error" })
          return
        }

        await insertTxn({
          variables: data,
        })
        toast.success("Payout successfully")
      }
      refetch()
      setSelectedPayouts([])
      setTrigger((prev) => prev + 1)
    } catch {
      toast.error("Error Payout")
    }
  }

  return (
    <Stack spacing={"10px"} padding={"24px"}>
      <Stack alignItems={"end"}>
        <Button
          startIcon={<IconExport />}
          onClick={handleExport}
          sx={{
            color: "#6750A4",
            fontSize: "14px",
            fontWeight: "500",
            fontStyle: "normal",
            lineHeight: "20px",
            padding: "10px 12px",
          }}
        >
          EXPORT
        </Button>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Payment Name</TableCell>
              <TableCell>Bank Name</TableCell>
              <TableCell>Bank Account</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Selected Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kolPayouts.map((kolPayout: KolPayout, index: number) => (
              <Row
                key={`${index}:${trigger}`}
                trigger={trigger}
                row={kolPayout}
                selectedKolPayouts={selectedPayouts}
                handleSelectPayouts={handleSelectPayouts}
              />
            ))}
            <TableRow key={"total"}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    lineHeight: "20px",
                  }}
                >
                  {centsToUsd(totalUSDCents)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    lineHeight: "20px",
                  }}
                >
                  {centsToUsd(selectedUSDCents)}
                </Typography>
              </TableCell>
              <TableCell>
                {selectedPayouts.length > 0 ? (
                  <Button
                    onClick={handlePayout}
                    variant="contained"
                    sx={{
                      color: "#ffffff",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStyle: "normal",
                      lineHeight: "20px",
                      padding: "10px 12px",
                      backgroundColor: "#6750A4",
                      width: "84px",
                      height: "40px",
                      borderRadius: "20px",
                    }}
                  >
                    Payout
                  </Button>
                ) : (
                  <Typography
                    sx={{
                      color: "#6750A4",
                      fontSize: "14px",
                      fontWeight: "500",
                      fontStyle: "large",
                      lineHeight: "20px",
                      opacity: 0.3,
                      width: "84px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    PAYOUT
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  )
}

export default PendingPayouts2
